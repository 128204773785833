export class Inventory {
  movtype: string = "in";
  mov_type: string;
  material_id: number;
  qty: number;
  reference: string;
  unit: string;
  location_id: string;
  expiry_date: string;
  price: number;
  barcode: any;
  sku: number;
  total_materials:number;
  inventory_id: number;
  is_prepared: number;
  ingredient_material_id: number;
  id: number;
}
