export class CrmUser {
  id: string;
  first_name: string;
  last_name: string;
  mobile_number: string;
  dob: string;
  customer_id: string;
  delivery_area: string;
  city: string;
  country: string;
  created_at: string;
  email: string;
  preferred_currency: string;
  latitude: number;
  longitude: number;
  price: number;
  is_mobile_number_verified: number;
  is_push_notification_enable: number;
  gender: string;
  number_of_purchases: number;
  average_basket_size: number;
  total_spend: string;
  thumbnail: string;
}
