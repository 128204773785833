import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { User } from "../../models/user";

@Injectable({
  providedIn: "root",
})
export class UserApiService {
  apiURL: string = "/api";

  constructor(private httpClient: HttpClient) {}

  public getUsers(url?: string) {
    return this.httpClient.get<User[]>(`${this.apiURL}/users`);
  }
}
