import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ServerDataSource, ViewCell } from "ng2-smart-table";
import { HttpClient } from "@angular/common/http";
import { NbWindowService } from "@nebular/theme";
import { Router } from "@angular/router";
import { CrmUser } from "../../models/crm_user";
import { LanguageService } from "../../../language.service";
import * as translation from "../../../../assets/json/translation.json";

@Component({
  selector: "ngx-button-view",
  template: `
    <img *ngIf="renderValue" style="max-height:60px" src="{{ renderValue }}" />
    <img
      *ngIf="!renderValue"
      style="max-height:60px"
      src="/api/uploads/no-image.png"
    />
  `,
})
export class ButtonImageViewComponent implements ViewCell, OnInit {
  lang: any;
  translation: any = translation;
  renderValue: string;
  @Input() value: string | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    if (this.value) {
      this.renderValue = this.value.toString();
    }
  }

  onClick() {}
}

@Component({
  selector: "ngx-smart-table",
  templateUrl: "./crm-user-table.component.html",
  styles: [
    `
      nb-card {
        transform: translate3d(0, 0, 0);
      }
    `,
  ],
})
export class CrmUserTableComponent {
  lang: any;
  translation: any = translation;
  apiURL: string = "/api/";
  viewedCrm = new CrmUser();
  settings = {
    mode: "external",
    actions: {
      add: false,
      edit: true,
      delete: false,
    },
    edit: {
      editButtonContent: '<i class="nb-expand"></i>',
    },
    columns: {
      first_name: {
        title: "First Name",
        type: "string",
        filter: false,
      },
      last_name: {
        title: "Last Name",
        type: "string",
        filter: false,
      },
      email: {
        title: "Email",
        type: "string",
        filter: false,
      },
      mobile_number: {
        title: "Mobile",
        type: "number",
        filter: false,
      },
      gender: {
        title: "Gender",
        type: "string",
        filter: false,
      },
      thumbnail: {
        title: "Image",
        type: "custom",
        renderComponent: ButtonImageViewComponent,
        filter: false,
      },
    },
  };

  source: ServerDataSource;

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private windowService: NbWindowService,
    private router: Router
  ) {
    this.lang = this.languageService.lang;
    this.source = new ServerDataSource(this.http, {
      endPoint: "/api/crm-users",
    });
    this.http.get<any>(this.apiURL + "crm-users").subscribe((any) => {});
  }

  getSearch(key: string) {
    this.source = new ServerDataSource(this.http, {
      endPoint: "/api/crm-users/?k=" + key,
    });
  }

  onEdit(event): void {
    this.viewedCrm = event.data;

    this.router.navigate(["/pages/users/crm-user/" + this.viewedCrm.id]);
  }
}
