import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Category } from "../../models/category";

@Injectable({
  providedIn: "root",
})
export class ApiServiceCategory {
  apiURL: string = "/api";

  constructor(private httpClient: HttpClient) {}

  public createCategory(category: Category) {
    return this.httpClient.post(`${this.apiURL}/categories/`, category);
  }

  public updateCategory(category: Category) {
    return this.httpClient.put(
      `${this.apiURL}/categories/${category.id}`,
      category
    );
  }

  // public removeImage(category: Category) {
  //   return this.httpClient.put(`${this.apiURL}/categories/remove_image/${category.id}`, category);
  // }

  public deleteCategory(id: number) {
    return this.httpClient.delete(`${this.apiURL}/categories/${id}`);
  }

  public getCategoryById(id: number) {}

  public getCategories(url?: string) {
    return this.httpClient.get<Category[]>(`${this.apiURL}/categories`);
  }
}
