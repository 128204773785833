import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { User } from "../../models/user";

@Injectable({
  providedIn: "root",
})
export class ApiServiceUser {
  apiURL: string = "/api/";

  constructor(private httpClient: HttpClient) {}

  public createTable(user: User) {
    return this.httpClient.post(`${this.apiURL}users/`, user);
  }

  public findBrandByLocation() {
    return this.httpClient.get(`${this.apiURL}brands/`);
  }
  public fetchLoggedInUser() {
    return  this.httpClient.get(`${this.apiURL}fetch-logged-in-user/`);
  }

  public updateTable(user: User) {
    return this.httpClient.put(`${this.apiURL}users/${user.id}`, user);
  }

  public deleteTable(id: number) {
    return this.httpClient.delete(`${this.apiURL}users/${id}`);
  }

  public getTableById(id: number) {}

  public fetchBrandByUserId(user: User) {
    return this.httpClient.get(`${this.apiURL}brandsByUserId/${user.id}`);
  }

  public getUsers(url?: string) {
    return this.httpClient.get<User[]>(`${this.apiURL}users`);
  }
}
