import { InvoiceItem } from "./invoice_item";

export class Invoice {
  id: number;
  items_number: number;
  subtotal: number;
  vat: number;
  payable: number;
  discountpct: number;
  discountamount: number;
  paid: number;
  change: number;
  note: string;
  items: InvoiceItem[];
  uuid: string;
  info_name: string;
  info_address: string;
  info_phone: string;
  info_email: string;
  user: string;
  status: string;
  table_id: number;
  created_at: number;
  payment_method: string;
  order_id: number;
  invoice_id: number;
  period: string;
  plate_number: string;
  customer_invoice_id: string;
}
